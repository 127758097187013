<template>
    <div>
        <div class="mobileapp-wrapper" v-if="mobileCheck">
            <div class="carousel-wrapper carousel-wrapper-mobile">
                <div class="contactus-title">{{ $t('footer3') }}</div>

                <swiper ref="infoSwiper" :options="swiperOption2">
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen1.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen2.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen3.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen4.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen5.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen6.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen7.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen8.png"/></div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="mobile-screenshot"><img src="../assets/app/screen9.png"/></div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>

                <div class="download-btn-wrapper">
                    <a href="https://apps.apple.com/us/app/vindome/id1494570524" target="_blank"
                       class="home-download-ios"></a>
                    <a href="https://play.google.com/store/apps/details?id=com.vindome.wine&hl=en_US&gl=US"
                       target="_blank" class="home-download-android"></a>
                </div>
            </div>

            <div class="mobileapp-container">
                <div class="contactus-title2">{{ $t('app1') }}</div>
                <div class="mobileapp-items">
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon1"></div>
                        <div class="mobileapp-item-title">{{ $t('app2') }}</div>
                    </div>
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon2"></div>
                        <div class="mobileapp-item-title">{{ $t('app3') }}</div>
                    </div>
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon3"></div>
                        <div class="mobileapp-item-title">{{ $t('app4') }}</div>
                    </div>
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon4"></div>
                        <div class="mobileapp-item-title">{{ $t('app5') }}</div>
                    </div>
                </div>
            </div>

            <div class="mobileapp-testimonials">
                <div class="mobileapp-container">
                    <div class="contactus-title2">{{ $t('app6') }}</div>

                    <swiper ref="infoSwiper" :options="swiperOption2">
                        <swiper-slide v-for="(item, index) in swiper2Data" :key="index">
                            <div class="mobile-community-item">
                                <div class="testimonial-text" v-html="item.value"></div>
                                <div class="testimonial-ratings">
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                </div>
                                <div class="testimonial-name">{{item.name}}</div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>

            <div class="mobileapp-container people-notice-wrapper">
                <div class="contactus-title2">{{ $t('app7') }}</div>

                <swiper :options="swiperOptionMobile">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <div v-on:click="showPrivacy(item.article)" class="article-text">
                            <div :class="'testimonial-logo logo-' + item.name"></div>
                            <div class="testimonial-info" v-html="item.value"></div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>

        <div class="mobileapp-wrapper" v-else>
            <div class="carousel-wrapper">
                <div class="contactus-title">{{ $t('footer3') }}</div>

                <vueper-slides class="no-shadow" arrows-outside :alwaysRefreshClones="true" :infinite="true"
                               :dragging-distance="200" :visible-slides="3" :slide-ratio="1/4" :bullets="false"
                               :initSlide="10">
                    <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image"/>
                </vueper-slides>

                <div class="download-btn-wrapper">
                    <a href="https://apps.apple.com/us/app/vindome/id1494570524" target="_blank"
                       class="home-download-ios"></a>
                    <a href="https://play.google.com/store/apps/details?id=com.vindome.wine&hl=en_US&gl=US"
                       target="_blank" class="home-download-android"></a>
                </div>
            </div>

            <div class="mobileapp-container">
                <div class="contactus-title2">{{ $t('app1') }}</div>
                <div class="mobileapp-items">
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon1"></div>
                        <div class="mobileapp-item-title">{{ $t('app2') }}</div>
                    </div>
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon2"></div>
                        <div class="mobileapp-item-title">{{ $t('app3') }}</div>
                    </div>
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon3"></div>
                        <div class="mobileapp-item-title">{{ $t('app4') }}</div>
                    </div>
                    <div class="mobileapp-item">
                        <div class="mobileapp-item-icon mobileapp-item-icon4"></div>
                        <div class="mobileapp-item-title">{{ $t('app5') }}</div>
                    </div>
                </div>
            </div>

            <div class="mobileapp-testimonials">
                <div class="mobileapp-container">
                    <div class="contactus-title2">{{ $t('app6') }}</div>

                    <swiper ref="infoSwiper" :options="swiperOption2">
                        <swiper-slide v-for="(item, index) in swiper2Data" :key="index">
                            <div>
                                <div class="testimonial-text" v-html="item.value"></div>
                                <div class="testimonial-ratings">
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                    <div class="testimonial-rating"></div>
                                </div>
                                <div class="testimonial-name">{{item.name}}</div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>

            <div class="mobileapp-container people-notice-wrapper">
                <div class="contactus-title2">{{ $t('app7') }}</div>

                <swiper :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <div v-on:click="showPrivacy(item.article)" class="article-text">
                            <div :class="'testimonial-logo logo-' + item.name"></div>
                            <div class="testimonial-info" v-html="item.value"></div>
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>

        <v-dialog v-model="showStore" persistent max-width="376px">
            <v-card class="qr-popup">
                <div class="qr-close" v-on:click="hideQR"></div>
                <div class="qr-title">Download the app</div>
                <div :class="{'qr-image': true, 'appStore': appStore, 'googlePlay': googlePlay}"></div>
                <div class="stores">
                    <a v-on:click="() => { storeClick(true) }" href="https://apps.apple.com/app/vindome-wine/id1494570524" target="_blank" class="appstore"
                       v-if="appStore"></a>
                    <a v-on:click="() => { storeClick(false) }" href="https://play.google.com/store/apps/details?id=com.vindome.wine" target="_blank"
                       class="gplay" v-if="googlePlay"></a>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {mapGetters} from "vuex";
import {GCommon} from "@/store/getters";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: "MobileApp",
    head: {
        title: 'Mobile application | Fine Wine Trading App',
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: 'Buy and sell fine wines from your smartphone today! Vindome is the fast, secure and easy way to become a wine trader – right from your smartphone.'
            }
        ],
        bodyAttrs: {
            class: 'page-app trading-desk'
        }
    },
    components: { VueperSlides, VueperSlide, Swiper, SwiperSlide },
    directives: {
        swiper: directive
    },
    computed: {
        ...mapGetters({
            mobileCheck: GCommon.GET_MOBILE_VERSION
        })
    },
    data() {
        return {
            swiperOption2: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiper2Data: [
                {
                    name: 'Tommaso, Luxembourg ',
                    value: 'I find your application very well developed (thought out). Combining blockchain technology with investment in wine completely removes the problem of bottle traceability, which I personally think is one of the greatest difficult for this type of investments. The information on the wine is clear and I have also discovered a lot of new wines. The selections you make allow those who are less experienced (like me) to create a well-diversified wine portfolio. The only feature I would recommend is a search option on the wine market to avoid going through the entire list every time you look at the live market. But apart from this detail, I have nothing else to report.</br></br>If I had to sum up my experience as a user in one sentence I would say: “Vindome is the smartest way to invest in wine”! '
                },
                {
                    name: 'George, Switzerland',
                    value: 'Vindome offers a hassle-free way to buy and hold fine wines for personal consumption or as investment. Vindome\'s block-chain enabled traceability guarantees authenticity and that the wines have been stored in uninterrupted optimal conditions.'
                },
                {
                    name: 'Ed, Monaco',
                    value: 'For somebody who does not know too much about wine the selection of wine on offer is excellent and the information given takes the guess work out in choosing wines to invest in.</br>Setting up an account , managing your portfolio and using the app is very easy.   Also, the turnaround after having ordered wine for delivery was excellent considering the wine comes directly from the Chateaux and also taking into consideration they current COVID situation.'
                },
                {
                    name: 'Lucrezia, Pavia',
                    value: 'Do you like wine? If yes, Vindome is the right app for you. An alternative way to invest in wine. Whether buying, selling or learning more about this industry.</br>Its a friendly user app and the customer service is brilliant and very efficient.</br>If you have some money apart and you want to find an alternative way, i kindly invite to read more about the wine regulations related to vat and taxes. Least but not last, Vindome fees and prices are very competitive.'
                }
            ],
            swiperOption: {
                slidesPerView: 3,
                spaceBetween: 60,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                }
            },
            swiperOptionMobile: {
                slidesPerView: 1,
                spaceBetween: 60,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                }
            },
            swiperData: [
                {
                    name: 'dagospia',
                    article: 'dagospia',
                    value: 'Forget about Bitcoin! The safest investment these days  is wine ! – “Vindome, the app for trading fine wine” has arrived.'
                },
                {
                    name: 'larepubblica',
                    article: 'LaReppublica',
                    value: 'Vindome - the app that opens for the wallet of the younger generation.'
                },
                {
                    name: 'ilgiornale',
                    article: 'ilGiornale',
                    value: 'Investing in fine wines? With Vindome it’s a passion within reach of your smartphone.'
                },
                {
                    name: 'businessinsider',
                    article: 'BusinessInsider',
                    value: 'Vindome, the app for investing in the 5.1 billion dollar fine wine market. They have change the approach to fine wine investments and solve all previous issues connected to it, specially for the beginner collector.'
                },
                {
                    name: 'cosmopolitan',
                    article: 'Cosmopolitan',
                    value: 'Have you ever thought about the world of wine investment ? Vindome Gift Certificates allow you to offer in real time selections of valuable wine made for keeping. The Christmas gift for 2020 based on wine, bubbles and stars.'
                },
                {
                    name: 'digitalclub',
                    article: 'TheDigitalClub',
                    value: 'Vindome, the first app dedicated to online fine wine investment for traders, collectors and wine enthusiasts arrives in Italy!... Vindome introduces some real innovation to this market.'
                },
                {
                    name: 'mixerplanet',
                    article: 'MixerPlanet',
                    value: 'Vindome Gift Certificates are one of those rare presents where the value is destined to grow over time. Be there at the start as you give the gift of creating a passion.'
                },
                {
                    name: 'igrandivini',
                    article: 'iGrandiVini',
                    value: 'A new app that opens up the trading of great collectible wines even to non-professionals thanks to intuitive user experience and sophisticated technology.'
                },
                {
                    name: 'winenews',
                    article: 'WineNews',
                    value: 'Investing in fine wines, even without being an expert: here is Vindome, the app for wine trading.'
                },
                {
                    name: 'vanityfair',
                    article: 'VanityFair',
                    value: 'Wine is a medium to long term investment. We are talking about an average of 5 years even if it is possible to make punctual operations in the short term.'
                },
                {
                    name: 'vice',
                    article: 'vice',
                    value: 'Come diventare ricchi - o almeno provarci - con il vino'
                },
                {
                    name: 'jamesmagazine',
                    article: 'jamesmagazine',
                    value: 'Vindome, la prima app dedicata all’investimento online in vini pregiati per trader, collezionisti e appassionati, arriva in Italia.'
                }
            ],
            showStore: false,
            appStore: false,
            googlePlay: false,
            slides: [
                {
                    image: require('../assets/app/screen1.png')
                },
                {
                    image: require('../assets/app/screen2.png')
                },
                {
                    image: require('../assets/app/screen3.png')
                },
                {
                    image: require('../assets/app/screen4.png')
                },
                {
                    image: require('../assets/app/screen5.png')
                },
                {
                    image: require('@/assets/app/screen6.png')
                },
                {
                    image: require('@/assets/app/screen7.png')
                },
                {
                    image: require('@/assets/app/screen8.png')
                },
                {
                    image: require('@/assets/app/screen9.png')
                },
                {
                    image: require('../assets/app/screen1.png')
                },
                {
                    image: require('@/assets/app/screen2.png')
                },
                {
                    image: require('@/assets/app/screen3.png')
                },
                {
                    image: require('@/assets/app/screen4.png')
                },
                {
                    image: require('@/assets/app/screen5.png')
                },
                {
                    image: require('@/assets/app/screen6.png')
                },
                {
                    image: require('@/assets/app/screen7.png')
                },
                {
                    image: require('@/assets/app/screen8.png')
                },
                {
                    image: require('@/assets/app/screen9.png')
                },
                {
                    image: require('../assets/app/screen1.png')
                },
                {
                    image: require('@/assets/app/screen2.png')
                },
                {
                    image: require('@/assets/app/screen4.png')
                },
                {
                    image: require('@/assets/app/screen5.png')
                },
                {
                    image: require('@/assets/app/screen6.png')
                },
                {
                    image: require('@/assets/app/screen7.png')
                },
                {
                    image: require('@/assets/app/screen8.png')
                },
                {
                    image: require('@/assets/app/screen9.png')
                }
            ]
        }
    },
    methods: {
        storeClick(isAppStore) {
            this.$gtm.push({ event: isAppStore ? "AppStoreClick" : "GooglePlayClick" });
        },
        showQR(isAppStore) {
            this.showStore = true;
            this.appStore = isAppStore;
            this.googlePlay = !isAppStore;
        },
        hideQR() {
            this.showStore = false;
            this.appStore = false;
            this.googlePlay = false;
        },
        showPrivacy: function (article) {
            if (article === 'dagospia') {
                window.open('https://m.dagospia.com/ma-quale-bitcoin-il-vero-investimento-sicuro-e-il-vinoarriva-vindome-l-app-per-fare-254689');
            } else if (article === 'jamesmagazine') {
                window.open('https://www.jamesmagazine.it/news/vindome-lapp-investe-sui-vini-pregiati/');
            } else if (article === 'vice') {
                window.open('https://www.vice.com/it/article/k7anww/guadagnare-con-il-vino');
            } else {
                window.open(`https://vindome.net/Vindome_${article}.pdf`);
            }
        }
    }
}
</script>

<style scoped>
    .download-btn-wrapper {
        position: absolute;
        bottom: 39px;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
    }

    .qr-popup.v-card {
        border-radius: 24px;
        height: 421px;
    }

    .qr-image {
        width: 320px;
        height: 300px;
        margin: 0 auto;
    }

    .qr-image.appStore {
        background: #ffffff url('../assets/appstore-qr.svg') no-repeat center;
        background-size: 280px;
    }

    .qr-image.googlePlay {
        background: #ffffff url('../assets/gplay-qr.svg') no-repeat center;
        background-size: 280px;
    }

    .qr-title {
        text-align: center;
        font-size: 22.5px;
        line-height: 31.5px;
        color: #090D0E;
        padding-top: 20px;
    }

    .qr-close {
        position: absolute;
        z-index: 1;
        top: 16px;
        right: 16px;
        width: 40px;
        height: 40px;
        border-radius: 55%;
        background: #E6C67C url('../assets/icon-close.svg') no-repeat center;
    }

    .qr-close:hover {
        background-color: #F1E2C4;
        cursor: pointer;
    }

    .qr-popup .stores {
        margin: 0;
    }

    .qr-popup .appstore, .qr-popup .gplay {
        width: 118px;
        height: 38px;
        margin: 0 auto;
        display: block;
    }

    .home-download-android,
    .home-download-ios {
        display: inline-block;
        width: 163px;
        height: 48px;
    }

    .home-download-ios {
        background: url('../assets/app/appstore.png') no-repeat center;
        width: 144px;
        margin-right: 16px;
    }

    .home-download-android {
        background: url('../assets/app/gplay.png') no-repeat center;
    }

    .home-download-android:hover,
    .home-download-ios:hover {
        cursor: pointer;
    }

    .mobileapp-wrapper {
        font-family: 'Montserrat', Arial, Helvetica, sans-serif;
        background: #ffffff;
        padding: 127px 0 0 0;
    }

    .contactus-title {
        text-align: center;
        color: #303030;
        font-size: 49px;
        line-height: 49px;
        font-weight: 300;
        margin-bottom: 47px;
    }

    .carousel-wrapper {
        background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #F6F6F6 100%);
        height: 754px;
        position: relative;
    }

    .mobileapp-container {
        max-width: 1366px;
        margin: 0 auto;
    }

    .contactus-title2 {
        margin-top: 44px;
        margin-bottom: 60px;
        font-size: 40px;
        line-height: 47px;
        font-weight: 400;
        color: #303030;
        text-align: center;
    }

    .mobileapp-items {
        margin: 0 75px 55px;
    }

    .mobileapp-item {
        display: inline-block;
        width: calc(25% - 4px);
        vertical-align: top;
    }

    .mobileapp-item-icon {
        width: 100px;
        height: 100px;
        background: url('../assets/app/image.png') no-repeat center;
        border-radius: 6px;
        margin: 0 auto 25px;
    }

    .mobileapp-item-icon1 {
        background: url('../assets/app/fast.png') no-repeat center;
    }

    .mobileapp-item-icon2 {
        background: url('../assets/app/direct.png') no-repeat center;
    }

    .mobileapp-item-icon3 {
        background: url('../assets/app/immersive.png') no-repeat center;
    }

    .mobileapp-item-icon4 {
        background: url('../assets/app/personalized.png') no-repeat center;
    }

    .mobileapp-item-title {
        font-size: 25px;
        line-height: 35px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
    }

    .mobileapp-testimonials {
        background: #F6F6F6;
        height: 488px;
    }

    .mobileapp-testimonials .contactus-title2 {
        padding-top: 44px;
        margin-top: 0;
    }

    .testimonial-text {
        max-width: 1024px;
        margin: 0 auto;
        font-size: 17px;
        line-height: 22px;
    }

    .testimonial-name {
        color: #303030;
        font-weight: 700;
        font-size: 14px;
    }

    .testimonial-logo {
        width: 161px;
        height: 89px;
        margin: 0 auto 19px;
    }

    .logo-decanter {
        background: url('../assets/app/decanter.png') no-repeat center;
    }

    .logo-winespectator {
        background: url('../assets/app/winespectator.png') no-repeat center;
    }

    .logo-saveur {
        background: url('../assets/app/saveur.png') no-repeat center;
    }

    .logo-larepubblica {
        background: url('../assets/app/larepubblica.png') no-repeat center;
        background-size: contain;
    }

    .logo-ilgiornale {
        background: url('../assets/app/ilgiornale.png') no-repeat center;
        background-size: contain;
    }

    .logo-businessinsider {
        background: url('../assets/app/businessinsider.png') no-repeat center;
        background-size: contain;
    }

    .logo-cosmopolitan {
        background: url('../assets/app/cosmopolitan.png') no-repeat center;
        background-size: contain;
    }

    .logo-digitalclub {
        background: url('../assets/app/digitalclub.png') no-repeat center;
        background-size: contain;
    }

    .logo-mixerplanet {
        background: url('../assets/app/mixerplanet.png') no-repeat center;
        background-size: contain;
    }

    .logo-igrandivini {
        background: url('../assets/app/igrandivini.png') no-repeat center;
        background-size: contain;
    }

    .logo-vanityfair {
        background: url('../assets/app/vanityfair.svg') no-repeat center;
        background-size: contain;
    }

    .logo-winenews {
        background: url('../assets/app/winenews.png') no-repeat center;
        background-size: contain;
    }

    .logo-dagospia {
        background: url('../assets/app/dagospia.png') no-repeat center;
        background-size: contain;
    }

    .logo-vice {
        background: url('../assets/app/vice.svg') no-repeat center;
        background-size: contain;
    }

    .logo-jamesmagazine {
        background: url('../assets/app/jamesmagazine.png') no-repeat center;
        background-size: contain;
    }

    .testimonial-info {
        font-weight: 400;
        font-size: 25px;
        line-height: 120%;
        text-align: center;
        color: #303030;
    }

    .testimonial-ratings {
        display: block;
        margin: 30px auto 5px;
    }

    .testimonial-rating {
        display: inline-block;
        margin-right: 7px;
        width: 33px;
        height: 31px;
        background: url('../assets/app/grade-24px 2.png') no-repeat center;
    }

    .testimonial-rating:last-child {
        margin-right: 0;
    }

    .article-text:hover {
        cursor: pointer;
    }

    @media (max-width: 767px) {
        .contactus-title,
        .contactus-title2 {
            font-size: 28px;
            line-height: 30px;
        }

        .mobileapp-items {
            margin: 0 15px 30px;
        }

        .mobileapp-item {
            width: calc(50% - 3px);
        }

        .mobileapp-item-title {
            font-size: 18px;
        }

        .mobileapp-testimonials .contactus-title2 {
            padding-top: 30px;
            margin-bottom: 40px;
        }

        .mobile-community-item {
            width: calc(100% - 100px);
            margin: 0 auto;
        }

        .testimonial-text {
            font-size: 15px;
        }

        .mobileapp-testimonials {
            height: auto;
        }

        .testimonial-info {
            padding: 0 15px;
        }

        .mobile-screenshot {
            width: calc(100% - 160px);
            margin: 0 auto;
        }

        .mobile-screenshot img {
            width: 100%;
        }
    }
</style>
<style>
    .swiper-container {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        height: 300px;
        width: 100%;
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        box-sizing: content-box;
    }

    .swiper-container-no-flexbox .swiper-slide {
        float: left
    }

    .swiper-container-vertical > .swiper-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        box-sizing: content-box
    }

    .swiper-container-android .swiper-slide, .swiper-wrapper {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    .swiper-container-multirow > .swiper-wrapper {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .swiper-container-free-mode > .swiper-wrapper {
        transition-timing-function: ease-out;
        margin: 0 auto
    }

    .swiper-slide {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform
    }

    .swiper-slide.swiper-slide-active .testimonial-item {
        border-right: 1px solid #F0F0F0;
        padding-left: 0;
        padding-right: 45px;
    }

    .show-mobile .swiper-slide.swiper-slide-active .testimonial-item {
        border-right: 0;
        padding-right: 0;
    }

    .swiper-invisible-blank-slide {
        visibility: hidden
    }

    .swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
        height: auto
    }

    .swiper-container-autoheight .swiper-wrapper {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        transition-property: height, -webkit-transform;
        transition-property: transform, height;
        transition-property: transform, height, -webkit-transform
    }

    .swiper-container-3d {
        -webkit-perspective: 1200px;
        perspective: 1200px
    }

    .swiper-container-3d .swiper-cube-shadow, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-wrapper {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d
    }

    .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 10
    }

    .swiper-container-3d .swiper-slide-shadow-left {
        background-image: linear-gradient(270deg, rgba(0, 0, 0, .5), transparent)
    }

    .swiper-container-3d .swiper-slide-shadow-right {
        background-image: linear-gradient(90deg, rgba(0, 0, 0, .5), transparent)
    }

    .swiper-container-3d .swiper-slide-shadow-top {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, .5), transparent)
    }

    .swiper-container-3d .swiper-slide-shadow-bottom {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), transparent)
    }

    .swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper {
        -ms-touch-action: pan-y;
        touch-action: pan-y
    }

    .swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper {
        -ms-touch-action: pan-x;
        touch-action: pan-x
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 50%;
        width: 27px;
        height: 44px;
        margin-top: -22px;
        z-index: 10;
        cursor: pointer;
        background-size: 27px 44px;
        background-position: 50%;
        background-repeat: no-repeat
    }

    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        opacity: .35;
        cursor: auto;
        pointer-events: none
    }

    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
        left: 50px;
        right: auto
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
        right: 50px;
        left: auto
    }

    .swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23fff'/%3E%3C/svg%3E")
    }

    .swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23fff'/%3E%3C/svg%3E")
    }

    .swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z'/%3E%3C/svg%3E")
    }

    .swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z'/%3E%3C/svg%3E")
    }

    .swiper-pagination {
        position: absolute;
        text-align: center;
        transition: opacity .3s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        z-index: 10
    }

    .swiper-pagination.swiper-pagination-hidden {
        opacity: 0
    }

    .swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
        bottom: 10px;
        left: 0;
        width: 100%
    }

    .swiper-pagination-bullets-dynamic {
        overflow: hidden;
        font-size: 0
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
        -webkit-transform: scale(.33);
        transform: scale(.33);
        position: relative
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
        -webkit-transform: scale(.66);
        transform: scale(.66)
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
        -webkit-transform: scale(.33);
        transform: scale(.33)
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
        -webkit-transform: scale(.66);
        transform: scale(.66)
    }

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
        -webkit-transform: scale(.33);
        transform: scale(.33)
    }

    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 100%;
        background: #000;
        opacity: .2
    }

    button.swiper-pagination-bullet {
        border: none;
        margin: 0;
        padding: 0;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #007aff
    }

    .swiper-container-vertical > .swiper-pagination-bullets {
        right: 10px;
        top: 50%;
        -webkit-transform: translate3d(0, -50%, 0);
        transform: translate3d(0, -50%, 0)
    }

    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 6px 0;
        display: block
    }

    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 8px
    }

    .swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
        display: inline-block;
        transition: top .2s, -webkit-transform .2s;
        transition: transform .2s, top .2s;
        transition: transform .2s, top .2s, -webkit-transform .2s
    }

    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 4px
    }

    .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        white-space: nowrap
    }

    .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
        transition: left .2s, -webkit-transform .2s;
        transition: transform .2s, left .2s;
        transition: transform .2s, left .2s, -webkit-transform .2s
    }

    .swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
        transition: right .2s, -webkit-transform .2s;
        transition: transform .2s, right .2s;
        transition: transform .2s, right .2s, -webkit-transform .2s
    }

    .swiper-pagination-progressbar {
        background: rgba(0, 0, 0, .25);
        position: absolute
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background: #007aff;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: left top;
        transform-origin: left top
    }

    .swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        -webkit-transform-origin: right top;
        transform-origin: right top
    }

    .swiper-container-horizontal > .swiper-pagination-progressbar {
        width: 100%;
        height: 4px;
        left: 0;
        top: 0
    }

    .swiper-container-vertical > .swiper-pagination-progressbar {
        width: 4px;
        height: 100%;
        left: 0;
        top: 0
    }

    .swiper-pagination-white .swiper-pagination-bullet-active {
        background: #fff
    }

    .swiper-pagination-progressbar.swiper-pagination-white {
        background: hsla(0, 0%, 100%, .25)
    }

    .swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
        background: #fff
    }

    .swiper-pagination-black .swiper-pagination-bullet-active {
        background: #000
    }

    .swiper-pagination-progressbar.swiper-pagination-black {
        background: rgba(0, 0, 0, .25)
    }

    .swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
        background: #000
    }

    .swiper-scrollbar {
        border-radius: 10px;
        position: relative;
        -ms-touch-action: none;
        background: rgba(0, 0, 0, .1)
    }

    .swiper-container-horizontal > .swiper-scrollbar {
        position: absolute;
        left: 1%;
        bottom: 3px;
        z-index: 50;
        height: 5px;
        width: 98%
    }

    .swiper-container-vertical > .swiper-scrollbar {
        position: absolute;
        right: 3px;
        top: 1%;
        z-index: 50;
        width: 5px;
        height: 98%
    }

    .swiper-scrollbar-drag {
        height: 100%;
        width: 100%;
        position: relative;
        background: rgba(0, 0, 0, .5);
        border-radius: 10px;
        left: 0;
        top: 0
    }

    .swiper-scrollbar-cursor-drag {
        cursor: move
    }

    .swiper-zoom-container {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center
    }

    .swiper-zoom-container > canvas, .swiper-zoom-container > img, .swiper-zoom-container > svg {
        max-width: 100%;
        max-height: 100%;
        -o-object-fit: contain;
        object-fit: contain
    }

    .swiper-slide-zoomed {
        cursor: move
    }

    .swiper-lazy-preloader {
        width: 42px;
        height: 42px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -21px;
        margin-top: -21px;
        z-index: 10;
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-animation: swiper-preloader-spin 1s steps(12) infinite;
        animation: swiper-preloader-spin 1s steps(12) infinite
    }

    .swiper-lazy-preloader:after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%236c6c6c' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E");
        background-position: 50%;
        background-size: 100%;
        background-repeat: no-repeat
    }

    .swiper-lazy-preloader-white:after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' stroke='%23fff' stroke-width='11' stroke-linecap='round' d='M60 7v20'/%3E%3C/defs%3E%3Cuse xlink:href='%23a' opacity='.27'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(30 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(60 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(90 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(120 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.27' transform='rotate(150 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.37' transform='rotate(180 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.46' transform='rotate(210 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.56' transform='rotate(240 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.66' transform='rotate(270 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.75' transform='rotate(300 60 60)'/%3E%3Cuse xlink:href='%23a' opacity='.85' transform='rotate(330 60 60)'/%3E%3C/svg%3E")
    }

    @-webkit-keyframes swiper-preloader-spin {
        to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn)
        }
    }

    @keyframes swiper-preloader-spin {
        to {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn)
        }
    }

    .swiper-container .swiper-notification {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        z-index: -1000
    }

    .swiper-container-fade.swiper-container-free-mode .swiper-slide {
        transition-timing-function: ease-out
    }

    .swiper-container-fade .swiper-slide {
        pointer-events: none;
        transition-property: opacity
    }

    .swiper-container-fade .swiper-slide .swiper-slide {
        pointer-events: none
    }

    .swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
        pointer-events: auto
    }

    .swiper-container-cube {
        overflow: visible
    }

    .swiper-container-cube .swiper-slide {
        pointer-events: none;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        z-index: 1;
        visibility: hidden;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        width: 100%;
        height: 100%
    }

    .swiper-container-cube .swiper-slide .swiper-slide {
        pointer-events: none
    }

    .swiper-container-cube.swiper-container-rtl .swiper-slide {
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0
    }

    .swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
        pointer-events: auto
    }

    .swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-next + .swiper-slide, .swiper-container-cube .swiper-slide-prev {
        pointer-events: auto;
        visibility: visible
    }

    .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right, .swiper-container-cube .swiper-slide-shadow-top {
        z-index: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden
    }

    .swiper-container-cube .swiper-cube-shadow {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .6;
        -webkit-filter: blur(50px);
        filter: blur(50px);
        z-index: 0
    }

    .swiper-container-flip {
        overflow: visible
    }

    .swiper-container-flip .swiper-slide {
        pointer-events: none;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        z-index: 1
    }

    .swiper-container-flip .swiper-slide .swiper-slide {
        pointer-events: none
    }

    .swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
        pointer-events: auto
    }

    .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right, .swiper-container-flip .swiper-slide-shadow-top {
        z-index: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden
    }

    .swiper-container-coverflow .swiper-wrapper {
        -ms-perspective: 1200px
    }


    .page-landing .theme--light.v-application {
        background: #ffffff;
    }

    .page-landing .header-toolbar {
        box-shadow: none;
    }

    .page-landing .site-navigation,
    .page-landing .notifications-wrapper,
    .page-landing .site-navigation-my-account,
    .page-landing .header-login-btn {
        display: none;
    }

    .page-landing .locale-changer .site-navigation {
        display: block;
    }

    .page-landing .locale-changer {
        position: absolute;
        right: 30px;
        top: 25px;
    }

    .swiper-container {
        text-align: center;
        height: 325px;
    }

    .people-notice-wrapper .swiper-container {
        height: 430px;
    }

    .swiper-pagination-bullet {
        background: #707070;
        opacity: 0.64;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: var(--brand);
    }

    .swiper-button-next:focus,
    .swiper-button-prev:focus {
        outline: none;
    }

    .swiper-button-next {
        background-image: url('../assets/arrow_next_dark.svg');
    }

    .swiper-button-prev {
        background-image: url('../assets/arrow_prev_dark.svg');
    }

    .swiper-button-next, .swiper-button-prev {
        top: 80px
    }

    .page-app .theme--light.v-application {
        background: #ffffff;
    }

    .swiper-container {
        text-align: center;
        height: 325px;
    }

    .people-notice-wrapper .swiper-container {
        height: 430px;
        margin-bottom: 50px;
    }

    .swiper-pagination-bullet {
        background: #707070;
        opacity: 0.64;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: var(--brand);
    }

    .swiper-button-next:focus,
    .swiper-button-prev:focus {
        outline: none;
    }

    .swiper-button-next {
        background-image: url('../assets/arrow_next_dark.svg');
    }

    .swiper-button-prev {
        background-image: url('../assets/arrow_prev_dark.svg');
    }

    .swiper-button-next, .swiper-button-prev {
        top: 80px
    }

    .vueperslides {
        max-width: 1366px;
        margin: 0 auto;
        height: 492px;
    }

    .vueperslides__parallax-wrapper {
        height: 492px;
    }

    .vueperslide {
        background-size: contain;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .vueperslide.vueperslide--active {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media (max-width: 767px) {
        .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
            left: 15px;
        }

        .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
            right: 15px;
        }

        .mobileapp-testimonials .swiper-button-prev,
        .mobileapp-testimonials .swiper-button-next {
            display: none;
        }

        .mobileapp-testimonials .swiper-container,
        .carousel-wrapper-mobile .swiper-container {
            height: auto;
            padding-bottom: 50px;
        }

        .carousel-wrapper-mobile .swiper-button-prev,
        .carousel-wrapper-mobile .swiper-container-rtl .swiper-button-next,
        .carousel-wrapper-mobile .swiper-button-next,
        .carousel-wrapper-mobile .swiper-container-rtl .swiper-button-prev {
            top: 140px;
        }

        .carousel-wrapper-mobile .swiper-button-prev {
            left: 32px;
        }

        .carousel-wrapper-mobile .swiper-button-next {
            right: 32px;
        }
    }
</style>
